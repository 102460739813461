var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        [
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("span", { staticClass: "primary--text font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.headerTitle))
                ]),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.$store.dispatch(
                          "orgStore/fetchOrgSpaces",
                          _vm.$route.params.oid
                        )
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("refresh")])],
                  1
                )
              ],
              1
            ),
            _vm.fetchingSpaces
              ? _c("span", { staticClass: "caption text-uppercase" }, [
                  _vm._v("Fetching spaces data....")
                ])
              : _vm.orgSpaceSortedByLongId.length > 0
              ? _c("span", { staticClass: "caption" }, [
                  _vm._v(
                    _vm._s(_vm.orgSpaceSortedByLongId.length) + " spaces found"
                  )
                ])
              : _vm._e()
          ]),
          _c("v-spacer"),
          (_vm.isOrgFaculty || _vm.isOrgManager) &&
          _vm.spaceType === _vm.spaceTypes.EDUCATION_SPACE
            ? _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    exact: "",
                    color: "primary",
                    disabled: _vm.fetchingSpaces
                  },
                  on: {
                    click: function($event) {
                      return _vm.createSpaceAndApp("class")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("New Course")
                  ])
                ],
                1
              )
            : _vm._e(),
          (_vm.isOrgFaculty || _vm.isOrgManager) &&
          _vm.spaceType === _vm.spaceTypes.RESEARCH_SPACE
            ? _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    exact: "",
                    color: "primary",
                    disabled: _vm.fetchingSpaces
                  },
                  on: {
                    click: function($event) {
                      return _vm.createSpaceAndApp("research")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("New Research Project")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.isOrgManager && _vm.spaceType === _vm.spaceTypes.VENDOR_SPACE
            ? _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.fetchingSpaces,
                    text: "",
                    exact: "",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.createSpaceAndApp("dataset")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("New Dataset")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.$route.name === "home-dataset-spaces"
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    text: "",
                                    color: "primary",
                                    href:
                                      "https://docs.nuvolos.cloud/actions/work-with-data-in-nuvolos",
                                    target: "_blank"
                                  }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-1", attrs: { small: "" } },
                                  [_vm._v("menu_book")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("work with datasets")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4174600700
                  )
                },
                [
                  _c("span", [
                    _vm._v(
                      "Read the documentation on how to work with datasets in Nuvolos"
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm.orgSpaceSortedByLongId.length && _vm.showSearchIcon
            ? _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.fetchingSpaces,
                    color: "primary",
                    icon: ""
                  },
                  on: {
                    click: function($event) {
                      _vm.expand = !_vm.expand
                    }
                  }
                },
                [_c("v-icon", [_vm._v("search")])],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _vm.orgSpaceSortedByLongId.length && _vm.fetchingSpaces === false
            ? _c(
                "v-container",
                { attrs: { fluid: "", "grid-list-md": "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.expand,
                        expression: "expand"
                      }
                    ],
                    attrs: { autofocus: "", label: _vm.searchLabel },
                    model: {
                      value: _vm.searchSpace,
                      callback: function($$v) {
                        _vm.searchSpace = $$v
                      },
                      expression: "searchSpace"
                    }
                  }),
                  _c("v-data-iterator", {
                    attrs: {
                      items: _vm.orgSpaceSortedByLongId,
                      "items-per-page": _vm.itemsPerPage,
                      "footer-props": {
                        itemsPerPageOptions: _vm.itemsPerPageOptions
                      },
                      search: _vm.searchSpace,
                      "hide-default-footer": _vm.hideFooter
                    },
                    on: {
                      "update:itemsPerPage": function($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function($event) {
                        _vm.itemsPerPage = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return _vm._l(props.items, function(item, index) {
                              return _c(
                                "div",
                                { key: item.sid },
                                [
                                  index > 0
                                    ? _c("v-divider", {
                                        staticClass: "mb-2",
                                        staticStyle: {
                                          opacity: "0.25",
                                          "border-width": "thin"
                                        },
                                        attrs: { inset: "" }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "v-list-item",
                                    { attrs: { "two-line": "" } },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-large": "" } },
                                            [_vm._v(_vm._s(_vm.spaceIcon))]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name: "snapshot-overview",
                                                      params: {
                                                        oid:
                                                          _vm.$route.params.oid,
                                                        sid: item.sid,
                                                        iid: item.iid,
                                                        snid: item.snid
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-hover", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var hover =
                                                              ref.hover
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  class: [
                                                                    hover
                                                                      ? "secondary--text"
                                                                      : "primary--text",
                                                                    "mr-2",
                                                                    "subtitle-1",
                                                                    "font-weight-bold"
                                                                  ]
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.space_long_id
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-subtitle", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption mr-3 font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.space_description)
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          }
                        },
                        {
                          key: "no-results",
                          fn: function() {
                            return [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    value: true,
                                    color: "error",
                                    icon: "warning",
                                    text: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    'Your search for "' +
                                      _vm._s(_vm.searchSpace) +
                                      '" found no results.'
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3289599980
                    )
                  })
                ],
                1
              )
            : _vm.orgSpaceSortedByLongId.length === 0
            ? _c(
                "div",
                [
                  _vm.fetchingSpaces === false
                    ? _c(
                        "v-banner",
                        {
                          attrs: { "two-line": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "actions",
                                fn: function() {
                                  return [
                                    (_vm.isOrgFaculty || _vm.isOrgManager) &&
                                    _vm.spaceType !== "Dataset"
                                      ? _c(
                                          "div",
                                          [
                                            _vm.spaceType ===
                                            _vm.spaceTypes.EDUCATION_SPACE
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "info",
                                                      outlined: "",
                                                      text: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.createSpaceAndApp(
                                                          "class"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("add")]
                                                    ),
                                                    _vm._v(" new course ")
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.spaceType ===
                                            _vm.spaceTypes.EDUCATION_SPACE
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "info",
                                                                      text: "",
                                                                      href:
                                                                        "https://docs.nuvolos.cloud/quickstart/faculty-guides/education",
                                                                      target:
                                                                        "_blank"
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "documentation"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1981408195
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Read the documentation on how to create a course"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.spaceType ===
                                            _vm.spaceTypes.RESEARCH_SPACE
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      outlined: "",
                                                      text: "",
                                                      color: "info"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.createSpaceAndApp(
                                                          "research"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("add")]
                                                    ),
                                                    _vm._v(
                                                      " new research project "
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.spaceType ===
                                            _vm.spaceTypes.RESEARCH_SPACE
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "info",
                                                                      text: "",
                                                                      href:
                                                                        "https://docs.nuvolos.cloud/quickstart/faculty-guides/research",
                                                                      target:
                                                                        "_blank"
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "documentation"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1149370288
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Read the documentation on how to create a research project"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2244841528
                          )
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              attrs: {
                                slot: "icon",
                                color: "white",
                                size: "60"
                              },
                              slot: "icon"
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "x-large": "",
                                    icon: "info",
                                    color: "info"
                                  }
                                },
                                [_vm._v(" info ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column primary--text" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm.spaceType === _vm.spaceTypes.EDUCATION_SPACE
                                  ? _c("span", [_vm._v("No courses found")])
                                  : _vm._e(),
                                _vm.spaceType === _vm.spaceTypes.RESEARCH_SPACE
                                  ? _c("span", [_vm._v("No projects found")])
                                  : _vm._e(),
                                _vm.spaceType === _vm.spaceTypes.VENDOR_SPACE
                                  ? _c("span", [_vm._v("No datasets found")])
                                  : _vm._e()
                              ]),
                              _vm.spaceType === _vm.spaceTypes.EDUCATION_SPACE
                                ? _c("span", { staticClass: "primary--text" }, [
                                    _vm._v(
                                      "Currently you don't have any courses set up in this organization."
                                    )
                                  ])
                                : _vm._e(),
                              _vm.spaceType === _vm.spaceTypes.RESEARCH_SPACE
                                ? _c("span", { staticClass: "primary--text" }, [
                                    _vm._v(
                                      "Currently you don't have any research projects set up in this organization."
                                    )
                                  ])
                                : _vm._e(),
                              _vm.spaceType === _vm.spaceTypes.VENDOR_SPACE
                                ? _c("span", { staticClass: "primary--text" }, [
                                    _vm._v(
                                      "Currently this organization has no activated datasets. Please reach out to your organization manager to onboard a dataset subscription."
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm.fetchingSpaces
            ? _c(
                "div",
                _vm._l(
                  [
                    { id: "c1", opacity: 1 },
                    { id: "c2", opacity: 0.75 },
                    { id: "c3", opacity: 0.5 }
                  ],
                  function(item) {
                    return _c("div", { key: item.id }, [
                      _c(
                        "div",
                        { style: { opacity: item.opacity } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: { type: "list-item-three-line" }
                          })
                        ],
                        1
                      )
                    ])
                  }
                ),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }